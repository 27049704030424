import React from 'react'
import Loadable from 'react-loadable'



const Octh = Loadable({
    loader: () => import('./homotopias3d'),
    
    loading: ({ timedOut }) =>
      timedOut ? (
        <blockquote>Por favor recarga la pagina.</blockquote>
      ) : (
        <div className="spinner">

        </div>
      ),
    timeout: 100000000,
  })
  
  
  export default class Homoc extends React.Component{
   

   render() 

    {
      return(

 <Octh/>
      
      )
    
  
}
  }